<template>
  <div>
    <div class="search">
      <div class="title">所属核销点列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <!-- <el-form-item label="昵称:">
          <el-input v-model="params.nickName"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        -->
        <el-form-item>
          <!-- <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button> -->
          <el-button type="primary" @click="dialogVisible = true">添加</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </el-form-item> 
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column type="index" width="50" align="center"> </el-table-column>
      <el-table-column prop="userName" label="核销人姓名" align="center"> </el-table-column>
     
      <el-table-column prop="phone" label="手机号" width="200" align="center">
      </el-table-column>
    
      <el-table-column prop="redeemName" label="核销点" align="center">
      </el-table-column>
      <el-table-column prop="strStatus" label="状态" width="180" align="center">
      </el-table-column>
      <el-table-column prop="addTime" label="时间" width="200" align="center">
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
            <el-button type="warning"  size="mini" @click="remove(scope.row)">删除</el-button>
          
        </template>
        </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="50%"
     @close="getList()"
      :close-on-click-modal="false"
    >
      <writeoffForUserAdd  @close="dialogVisible = false" :userId="params.userId"></writeoffForUserAdd>
     
    </el-dialog>
  </div>
</template>

<script>
import {
  writeoffForUserList,
  writeoffForUserListDel,
} from "../../../api/administrator.js";
import writeoffForUserAdd from './writeoffForUserAdd.vue';
export default {
    components:{writeoffForUserAdd},
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        userId: "",
      },
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      addRules: {
        tableData: [
          { required: true, message: "请输入活动名称", trigger: ["blur", "change"] },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.$route.query.userId ? (this.params.userId = this.$route.query.userId) : "";
    this.getList()
  },
  methods: {
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
    //   this.params.beginTime = this.date ? this.date[0] : "";
    //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await writeoffForUserList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    remove(row){
        this.$confirm('是否删除该所属核销点?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const { data } = await writeoffForUserListDel({userRedeemId:row.userRedeemId});
            if (data.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.getList();
            } else {
             
            }
          }).catch(() => {
              
          });
    
    }
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
